<template>
  <div class="relative">
    <div class="mb-10 flex items-center justify-between">
      <UiHeader
        :header="props.header"
        :header-link="props.headerLink"
        :header-position="props.headerPosition"
        :subheader="props.subheader"
        :header-style-class="props.headerStyleClass"
        header-layout="section-header mb-0"
        class="text-primary"
      />
      <UiButton
        v-if="props.button && props.button.text && props.button.link?.href"
        class="hidden md:inline-flex"
        variant="link"
        :uppercase="false"
        :to="props.button.link.href"
        icon-right="arrow"
      >
        {{ props.button.text }}
      </UiButton>
    </div>

    <UiSliderHorizontal
      v-if="props.slides"
      classes="swiper-news !pb-8 !lg:pb-0 lg:!-mx-3 lg:!px-3 xl:!-mx-0.5 xl:!px-0.5"
      class="swiper-news"
      :less-sliders-on-view="true"
      :center-insufficient-slides="true"
      :options="sliderOptions"
    >
      <UiSliderBox
        v-for="(slide, index) in props.slides"
        :key="`sliderboxes-slide-${index}`"
        :image-url="slide.image?.publicUrl ?? ''"
        :image-width="slide.image?.properties?.dimensions?.width"
        :image-height="slide.image?.properties?.dimensions?.height"
        :icon="slide.icon"
        :header="slide.header ?? ''"
        :button-text="slide.button?.text"
        :button-link="slide.button?.link?.href"
      />
    </UiSliderHorizontal>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { type T3CeRawlplugSliderboxesProps } from './T3CeRawlplugSliderboxes.types'

const props = defineProps<T3CeRawlplugSliderboxesProps>()
const sliderOptions = computed(() => ({
  slidesPerView: 1.3,
  autoHeight: true,
  spaceBetween: 20,
  breakpoints: {
    640: {
      slidesPerView: 2,
      slidesOffsetAfter: 0,
    },
    1024: {
      slidesPerView: 3,
      slidesOffsetAfter: 0,
    },
    1300: {
      slidesPerView: 4,
      slidesOffsetAfter: 0,
    },
    1366: {
      slidesPerView: 5,
      slidesOffsetAfter: 0,
    },
  },
}))
</script>
